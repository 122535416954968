<!-- openseadragon (osd) viewer -->
<div class="osd-container" [class.drawing]="regionDrawMode" [class.on-error]="failedToLoad">
  <!-- in case of an error -->
  <ng-content select="[navigationArrows]"></ng-content>
  <app-status
    [status]="defaultFailureStatus"
    [url]="imageFileValue?.fileUrl"
    [representation]="'still-image'"
    *ngIf="failedToLoad"></app-status>
</div>

<div class="toolbar">
  <ng-content select="[slider]" />
  <div class="toolbar-bottom">
    <!-- vertical more button with menu to open and copy iiif url -->
    <button
      mat-icon-button
      data-cy="more-vert-image-button"
      [matMenuTriggerFor]="more"
      matTooltip="More"
      matTooltipPosition="above">
      <mat-icon>more_vert</mat-icon>
    </button>

    <span class="action-buttons">
      <button
        data-cy="still-image-share-button"
        mat-icon-button
        [matMenuTriggerFor]="share"
        [disabled]="isReadStillImageExternalFileValue"
        matTooltip="Share"
        matTooltipPosition="above">
        <mat-icon>share</mat-icon>
      </button>

      <button
        data-cy="still-image-download-button"
        mat-icon-button
        matTooltip="Download"
        [disabled]="isReadStillImageExternalFileValue || failedToLoad || !userCanView"
        (click)="download()">
        <mat-icon>download</mat-icon>
      </button>
      <button
        data-cy="still-image-region-button"
        mat-icon-button
        id="DSP_OSD_DRAW_REGION"
        matTooltip="Draw Region"
        [disabled]="failedToLoad || !usercanEdit || isReadStillImageExternalFileValue"
        (click)="drawButtonClicked()"
        [class.active]="regionDrawMode">
        <mat-icon svgIcon="draw_region_icon"></mat-icon>
      </button>
    </span>

    <span class="fill-remaining-space"></span>

    <!-- navigation in case of compound object: first page and previous page -->
    <!--<app-compound-navigation *ngIf="compoundNavigation"></app-compound-navigation>-->
    <ng-content select="[navigation]"></ng-content>

    <span class="fill-remaining-space"></span>

    <!-- right side buttons -->
    <span>
      <button
        data-cy="still-image-settings-button"
        mat-icon-button
        id="DSP_OSD_SETTINGS"
        matTooltip="Settings"
        [matMenuTriggerFor]="settings"
        [disabled]="failedToLoad || this.isReadStillImageExternalFileValue">
        <mat-icon>settings</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_ZOOM_OUT" matTooltip="Zoom out" [disabled]="failedToLoad">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_ZOOM_IN" matTooltip="Zoom in" [disabled]="failedToLoad">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_HOME" matTooltip="Reset zoom" [disabled]="failedToLoad">
        <mat-icon>other_houses</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_FULL_PAGE" matTooltip="Open in fullscreen" [disabled]="failedToLoad">
        <mat-icon>fullscreen</mat-icon>
      </button>
    </span>
  </div>
</div>

<mat-menu #more="matMenu" class="representation-menu">
  <button class="menu-content" mat-menu-item (click)="openImageInNewTab(imageFileValue?.fileUrl)">
    Open IIIF URL in new tab
  </button>
  <button
    class="menu-content"
    data-cy="replace-image-button"
    mat-menu-item
    [disabled]="failedToLoad || !usercanEdit"
    (click)="replaceImage()">
    {{isReadStillImageExternalFileValue ? 'Replace external file url':'Replace file'}}
  </button>
</mat-menu>

<mat-menu #share="matMenu" class="res-share-menu">
  <button
    mat-menu-item
    (click)="openSnackBar('IIIF URL copied to clipboard!')"
    [cdkCopyToClipboard]="imageFileValue?.fileUrl">
    <mat-icon>content_copy</mat-icon>
    Copy IIIF URL to clipboard
  </button>
  <button
    mat-menu-item
    [cdkCopyToClipboard]="imageFileValue?.arkUrl"
    (click)="openSnackBar('ARK URL copied to clipboard!')">
    <mat-icon>content_copy</mat-icon>
    Copy ARK url to clipboard
  </button>
</mat-menu>
<mat-menu #settings="matMenu" class="settings-menu">
  <button class="menu-content" mat-menu-item (click)="imageFormatIsPng.next(false)">
    <mat-icon *ngIf="!isPng">check</mat-icon>JPG
  </button>
  <button mat-menu-item [disabled]="failedToLoad" (click)="imageFormatIsPng.next(true)">
    <mat-icon *ngIf="isPng">check</mat-icon>PNG
  </button>
</mat-menu>
